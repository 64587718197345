<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode === 'en'">VIRTUE AND WISDOM EDUCATION CENTER</h1>
          <h1 v-else>德智樂教育中心</h1>

          <div class="description" v-if="langcode == 'en'">
            Your Child’s Best Partner in Growth<br /><br />
            Located in To Kwa Wan, Wisdom & Joy Education Center is a high-quality educational institution specializing
            in homework tutoring and subject-specific education for kindergarten, primary, and secondary school
            students. Since its establishment, it has served local students for decades and has earned the trust of both
            parents and students.
          </div>
          <div class="description" v-if="langcode == 'zh'">
            您的孩子成長之路的最佳夥伴<br /><br />
            位於土瓜灣的德智樂教育中心，是一家專注於中小學及幼稚園功課輔導和專科教育的優質教育機構。自開業以來，已經服務了本區的學生達數十年，深受家長和學生的信賴。
          </div>

          <ul class="icons">
            <li v-if="langcode === 'en'">{{ $t('Address') }}: FLAT B 1/F, KA WAI COURT, NO.50-52 PAU CHUNG STREET, TO
              KWA WAN, KOWLOON</li>
            <li v-else>{{ $t('Address') }}: 九龍土瓜灣炮仗街50－52號嘉威閣1樓B室</li>
            <li>{{ $t('Email') }}: virtuenwisdom@outlook.com</li>
            <li>{{ $t('Tel') }}: 852-69994544</li>
          </ul>
          <a href="#" @click="viewAboutUs" style="margin-right: 10px">
            {{ $t('Our Mission') }}
          </a>
          <a href="#" @click="viewService">
            {{ $t('Why Us?') }}
          </a>
        </header>

        <!-- Footer -->
        <footer id="footer">
          <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
          <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
          <ul class="copyright">
            <li>&copy; VIRTUE AND WISDOM EDUCATION CENTER</li>
          </ul>
        </footer>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en'">Our Mission</h1>
          <h1 v-else>我們的使命</h1>
          <div class="description" v-if="langcode == 'en'">
            Holistic Development: We are committed to providing a well-rounded education, ensuring that students develop
            in morality, intellect, physical fitness, social skills, and aesthetics. <br /><br />
            Personalized Teaching: We offer professional guidance and support tailored to each student’s needs, helping
            them achieve academic progress.<br /><br />
            Diverse Curriculum: We provide a wide range of course options, including comprehensive homework tutoring and
            specialized subject education, to meet the needs of students at different grade levels.<br /><br />
          </div>
          <div class="description" v-if="langcode == 'zh'">
            全人發展：我們致力於提供全面性的教育，讓學生在德、智、體、群、美五育方面均衡發展。<br />
            個性化教學：根據每位學生的需求，提供專業的輔導和支持，讓他們在學業上取得進步。<br />
            豐富課程：提供多元化的課程選擇，包括全科功課輔導和專科教育，滿足不同年級學生的需求。<br />
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler"/>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="our-service">
      <div id="main-3">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en'">Why Us?</h1>
          <h1 v-else>為什麼選擇德智樂教育中心？</h1>
          <div class="description" v-if="langcode == 'en'">
            Extensive Experience: With decades of experience, we have accumulated a wealth of teaching expertise and
            success stories. <br /><br />
            Qualified & Passionate Teachers: Our dedicated team of professional educators is committed to each student’s
            growth and success. <br /><br />
            Convenient Location: Situated in To Kwa Wan with easy access to transportation, making it convenient for
            parents to drop off and pick up their children.<br /><br />
            Join us and let your child thrive at Wisdom & Joy Education Center!
          </div>
          <div class="description" v-if="langcode == 'zh'">
            在學校裡，小朋友將有機會體驗各種舞蹈類型。我們的教師團隊皆經專業訓練，並擁有多年教育經驗，能夠為小學生帶來高品質、有趣及創意的舞蹈教學。
            我們深信，在領導小朋友探索各種美術表現之際，可以培養出孩子們的藝術感與自信，並助他們發掘未知的才能。<br /><br />
            經驗豐富：數十年的經驗，累積了豐富的教學經驗和成功案例。<br />
            優質師資：我們的教師團隊專業、熱情，致力於每位學生的成長。<br />
            便利位置：位於土瓜灣，交通便利，方便家長接送。<br />
            加入我們，讓您的孩子在德智樂教育中心茁壯成長！<br />
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler"/>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/03.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted() {
    const langcode = Cookies.get('langcode');
    if (langcode) {
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [],
      defaultReplyEn: {
        agent: 'bot',
        type: 'button',
        text: 'Hello, Welcome to 德智樂教育中心. How can I help you? ',
        disableInput: false,
        options: [
          {
            text: 'Your business hours is ?',
            value: 'business_hour',
            action: 'postback' // Request to API
          },
          {
            text: 'What courses does the education center offer?',
            value: 'courses',
            action: 'postback' // Request to API
          },
          {
            text: 'How can I enroll in a course?',
            value: 'enroll',
            action: 'postback' // Request to API
          },
          {
            text: 'How are the class schedules arranged?',
            value: 'schedules',
            action: 'postback' // Request to API
          },
          {
            text: 'Do you offer trial classes?',
            value: 'trial',
            action: 'postback' // Request to API
          },
          {
            text: 'Can I make up a missed class?',
            value: 'missed_class',
            action: 'postback' // Request to API
          },
          {
            text: 'Any contact method?',
            value: 'contact',
            action: 'postback' // Request to API
          },
        ],
      },
      defaultReply: {
        agent: 'bot',
        type: 'button',
        text: '你好，歡迎來到德智樂教育中心. 請問我能如何幫助你？',
        disableInput: false,
        options: [
          {
            text: '你們的營業時間是？',
            value: 'business_hour',
            action: 'postback' // Request to API
          },
          {
            text: '教育中心提供哪些課程？',
            value: 'courses',
            action: 'postback' // Request to API
          },
          {
            text: '我該如何報名參加課程？',
            value: 'enroll',
            action: 'postback' // Request to API
          },
          {
            text: '課程時間是如何安排的？',
            value: 'schedules',
            action: 'postback' // Request to API
          },
          {
            text: '你們有提供試聽課嗎？',
            value: 'trial',
            action: 'postback' // Request to API
          },
          {
            text: '我可以補課嗎？',
            value: 'missed_class',
            action: 'postback' // Request to API
          },
          {
            text: '有聯絡方式嗎？',
            value: 'contact',
            action: 'postback' // Request to API
          },
        ],
      },
      replyTextsEn: [
        {
          'question': 'business_hour',
          'question_text': 'Your business hour is?',
          'keywords': ['business hour'],
          'answer': 'Our business hours is 10:00 - 17:00.',
        },
        {
          'question': 'courses',
          'question_text': 'What courses does the education center offer?',
          'keywords': ['courses'],
          'answer': 'We offer a variety of courses, including language learning, mathematics and sciences, humanities and social studies, arts, and music, suitable for students of different age groups.',
        },
        {
          'question': 'enroll',
          'question_text': 'How can I enroll in a course?',
          'keywords': ['enroll'],
          'answer': 'You can register online through our official website or visit the education center to fill out a registration form. If you have any questions, feel free to contact our customer service.',
        },
        {
          'question': 'schedules',
          'question_text': 'How are the class schedules arranged?',
          'keywords': ['schedules'],
          'answer': 'We offer weekday and weekend classes, with both daytime and evening sessions, allowing students to choose a schedule that best fits their availability.',
        },
        {
          'question': 'trial',
          'question_text': 'Do you offer trial classes?',
          'keywords': ['trial'],
          'answer': 'Yes, we provide free trial classes for selected courses so that you can experience the teaching style and content before enrolling.',
        },
        {
          'question': 'missed_class',
          'question_text': 'Can I make up a missed class?',
          'keywords': ['missed_class'],
          'answer': 'Depending on the course type, some classes offer make-up sessions or online recorded playback options. Please contact our course advisors for more details.',
        },
        {
          'question': 'contact',
          'keywords': ['contact'],
          'answer': 'Please feel free to contact with us by mail or phone: virtuenwisdom@outlook.com / 852-69994544',
        },
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'question_text': '你們的營業時間是?',
          'keywords': ['營業時間'],
          'answer': '我們的營業時間為 10:00 至 17:00。',
        },
        {
          'question': 'courses',
          'question_text': '教育中心提供哪些課程?',
          'keywords': ['課程'],
          'answer': '我們提供多種課程，包括語言學習、數學與科學、人文與社會研究、藝術及音樂，適合不同年齡層的學生。',
        },
        {
          'question': 'enroll',
          'question_text': '我該如何報名參加課程?',
          'keywords': ['報名'],
          'answer': '您可以通過我們的官方網站在線註冊，或親自到教育中心填寫報名表。如果您有任何問題，請隨時聯繫我們的客服。',
        },
        {
          'question': 'schedules',
          'question_text': '課程時間是如何安排的?',
          'keywords': ['安排'],
          'answer': '我們提供平日和週末課程，並有日間和晚間班，讓學生可以選擇最適合自己時間的課程安排。',
        },
        {
          'question': 'trial',
          'question_text': '你們有提供試聽課嗎?',
          'keywords': ['試聽課'],
          'answer': '是的，我們為部分課程提供免費試聽課，讓您在報名之前體驗我們的教學風格和內容。',
        },
        {
          'question': 'missed_class',
          'question_text': '我可以補課嗎？',
          'keywords': ['補課'],
          'answer': '根據課程類型，有些課程提供補課或線上錄製回放選項。請聯繫我們的課程顧問以了解更多詳情。',
        },
        {
          'question': 'contact',
          'question_text': '有聯絡方式嗎？',
          'keywords': ['聯絡'],
          'answer': '請隨時通過郵件或電話與我們聯繫：virtuenwisdom@outlook.com / 852-69994544。',
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods: {
    viewAboutUs() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewService() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const service = document.querySelector('.our-service');
      service.style.display = 'block';
    },
    backIndex() {
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const service = document.querySelector('.our-service');
      service.style.display = 'none';
    },
    async loadConfig() {
      return new Promise(async (resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if (status !== 200 && status !== 401) {
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if (status === 401) {
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent() {
      try {
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      } catch (err) {
        console.log(err);
      }
    },
    selectLang(langcode) {
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction) {
      const searchKeywords = this.searchKeywords;

      let question = userAction['value'];
      if (!question) {
        question = userAction['text'].toLowerCase();
      }
      let answer = this.replyTextsEn.find(function (item) {
        if (item.question === question) {
          return true;
        }
        if (item.question_text === question) {
          return true;
        }
        if (searchKeywords(item.keywords, question)) {
          return true;
        }
      })
      if (this.langcode !== 'en') {
        answer = this.replyTexts.find(function (item) {
          if (item.question === question) {
            return true;
          }
          if (item.question_text === question) {
            return true;
          }
          if (searchKeywords(item.keywords, question)) {
            return true;
          }
        })
      }
      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if (answer) {
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      } else {
        if(this.langcode !== 'en'){
          this.data.push(this.defaultReply);
        }else{
          this.data.push(this.defaultReplyEn);
        }
      }
    },
    searchKeywords(keywords, text) {
      const re = new RegExp('(' + keywords.join('|') + ')', 'g')
      const result = text.toLowerCase().match(re)
      if (result != null || result != null) {
        return true;
      }
      return false;
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
    langcode: state => state.langcode,
  }),
}
</script>

<style lang="scss">
h1 {
  text-align: left;
}

body.is-preload-1 #main {
  opacity: 1;
  right: 0;
}

.qkb-board {
  width: 700px;
}

#viewer .slide .image {
  @media screen and (min-width: 1300px) {
    background-size: 100%;
  }
}

.about-us {
  display: none;
}

.description {
  text-align: left;
  margin-bottom: 20px;
}
</style>
